
.featured-content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    .featured-index {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 100%;

        .featured-left {
            display: flex;
            flex-direction: column;
            position: relative;
            flex: 1;
            width: 1%;
            background-color: #F7F8FC;

            .featured-item {
                &:nth-child(2) {
                    margin-bottom: 64px;
                }

                &:nth-child(3) {
                    margin-bottom: 30px;

                    .item-title {
                        display: flex;
                        justify-content: space-between;

                        .upload-material {
                            width: 70px;
                            height: 30px;
                            background: #ECF5FF;
                            border: 1px solid #DCECFE;
                            border-radius: 4px;
                            color: #409EFF;
                            font-size: 14px;
                            text-align: center;
                            line-height: 30px;

                            &:hover {
                                color: #409EFF;
                                border-color: #c6e2ff;
                                background-color: #ecf5ff;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .item-title {
                    position: relative;
                    font-size: 16px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 4px;
                        width: 4px;
                        height: 16px;
                        background-color: #409EFF;
                    }

                    .text {
                        margin-left: 15px;
                    }
                }

                .featured-list {
                    width: 100%;
                    height: 120px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    box-sizing: border-box;

                    .dragg-box {
                        width: 100%;
                        display: flex;
                    }

                    .featured-list-item {
                        display: flex;
                        margin-right: 30px;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 140px;
                        /*min-width: 140px;*/
                        margin-right: 37px;
                        height: 120px;
                        background: #ffffff;
                        border-radius: 6px;

                        .inner-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        &:hover {
                            box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                        }

                        .item-cover {
                            width: 50px;
                            height: 50px;
                            line-height: 40px;
                            text-align: center;
                            border-radius: 50%;
                            cursor: pointer;

                            i {
                                font-size: 20px;
                                color: #fff;
                            }

                            img {
                                width: 50px;
                                height: 50px;
                            }

                            .app-icon {
                                max-width: 100%;
                                max-height: 100%;
                                border-radius: 5px;
                            }
                        }

                        &:nth-of-type(7) {
                            margin-right: 0;
                        }

                        .circle-box {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #ffffff;

                            ::v-deep .iconfont {
                                font-size: 20px;
                            }
                        }

                        .item-name {
                            margin-top: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .featured-topbox {
                margin: 16px 0 11px;
                display: flex;
                align-items: center;
                padding: 30px 30px 30px 0;
                position: relative;
                background: #FFFFFF;
                box-shadow: 0px 5px 10px 1px rgba(32, 32, 32, 0.05);

                span {
                    display: inline-block;
                    line-height: 1;
                    font-size: 14px;
                    padding: 8px 12px;
                    border-radius: 4px;
                    font-weight: 400;
                    cursor: pointer;
                }

                .class-item {
                    background: #2DC079;
                    box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                    border-radius: 20px;
                    color: #ffffff;
                }
            }

            .featured-top {
                flex: 1;
                height: 1%;
                background: #ffffff;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    margin-right: -37px !important;
                }

                ::v-deep .el-scrollbar__view {
                    margin-right: 20px;
                }

                .featured-mainbox {
                    width: 100%;

                    .mainbox-list {
                        min-height: 425px;
                        padding: 30px 60px;

                        .dragg-box {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        .mainbox-list-item {
                            cursor: pointer;
                            width: 33%;
                            margin-bottom: 50px;
                            display: flex;

                            .item-title {
                                margin-left: 30px;
                                display: flex;
                                align-items: center;
                            }
                        }

                        .no-data {
                            width: 100%;
                            font-size: 16px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.upload-resources-dialog {
    .info-form {
        .file {
            ::v-deep .el-form-item__content {
                position: relative;
                display: flex;
                flex-direction: column;
            }

            .file-name {
                display: flex;
                position: relative;
            }

            .upload-cover {
                width: 100px;
                height: 30px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 5px;
                color: #409EFF;
                line-height: 30px;
                text-align: center;
                margin-right: 15px;

                i.iconfont {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }

            .cover-input {
                position: absolute;
                top: 0;
                width: 100px;
                height: 30px;
                opacity: 0;
                cursor: pointer;
            }
        }

        .resources-class-cascader {
            width: 100%;
        }

        .select-course {
            width: 100%;
        }
    }
}

.app-detail-dialog {
    ::v-deep .el-dialog {
        ::v-deep .el-dialog__header {
            border-bottom: 1px solid #e6e7ea;
        }

        .title {
            text-align: center;
            margin: 0;
        }

        .content {
            display: flex;
            flex-direction: column;

            .h2 {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                margin: 10px 0;
            }

            .soft-introduction {
                text-align: center;
            }

            .soft-content {
                padding: 10px 50px;
                min-height: 200px;
            }
        }

        ::v-deep .el-dialog__footer {
            border-top: 1px solid #e6e7ea;
            padding: 20px;

            a {
                padding: 7px 20px;
                font-size: 14px;
                border-radius: 4px;
                margin-right: 20px;
            }

            a.home {
                border: 1px solid #DCDFE6;
                color: #606266;

                &:hover {
                    color: #409EFF;
                    border-color: #c6e2ff;
                    background-color: #ecf5ff;
                }
            }

            a.shop {
                color: #FFF;
                background-color: #409EFF;
                border-color: #409EFF;

                &:hover {
                    background: #66b1ff;
                    border-color: #66b1ff;
                    color: #FFF;
                }
            }
        }
    }
}

::v-deep .saveTemplate {
    .el-dialog__header {
        background: #409EFF;

        span {
            color: #fff;
            font-size: 16px;
        }

        .el-icon-close {
            color: #fff;
        }
    }

    .el-dialog__body {
        padding: 70px 0px 20px 50px;
    }

    .template-box {
        display: flex;
        flex-direction: column;

        .template-box-name {
            display: flex;
            align-items: center;
            color: #333333;
            font-size: 14px;
            padding-right: 60px;
        }

        .template-box-btn {
            margin-top: 70px;
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;

            .el-button {
                margin-left: 20px;
            }
        }
    }
}

.temp-popover {
    display: flex;
    flex-direction: column;

    .no-data {
        text-align: center;
    }

    .popover-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 30px;

        &:hover {
            background: #ECF5FF;

            span {
                color: #409EFF;
            }
        }

        div {
            color: #666666;
            font-size: 14px;
            padding-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        i {
            color: #666666;
            font-size: 12px;
            padding-right: 10px;
        }
    }
}

.select-positioning {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    box-shadow: 0px 1px 6px 1px rgba(64, 158, 255, 0.2);
    border-radius: 10px;
    margin-left: 20px;
    //position: absolute;
    //right: -20px;
    //top: -78px;
    //z-index: 99;
    background: #2DC079;

    i {
        font-size: 29px;
        color: #9CA9C3;
    }

    .hover-two {
        background: #2DC079;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 14px;
        //line-height: 60px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        font-weight: 400;
        cursor: pointer;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
